import { Box, Typography, Button } from '@mui/material';
import { keyframes } from '@emotion/react';
import { useLogin, usePrivy } from '@privy-io/react-auth';
import { useAccount } from 'wagmi';
const pulseAnimation = keyframes`
  0% { opacity: 0.4; }
  50% { opacity: 0.6; }
  100% { opacity: 0.4; }
`;
const StyledHeader = () => {
  const {
    isConnected
  } = useAccount();
  const {
    login
  } = useLogin();
  const {
    user,
    ready,
    authenticated,
    connectWallet
  } = usePrivy();
  const handleLoginClick = () => {
    if (!isConnected && user && ready && authenticated) {
      connectWallet();
    } else {
      login();
    }
  };
  return <Box sx={{
    background: 'linear-gradient(to top right, rgba(79, 70, 229, 0.9), rgba(16, 185, 129, 0.9))',
    borderRadius: '0.875rem',
    padding: '3rem',
    position: 'relative',
    overflow: 'hidden'
  }} data-sentry-element="Box" data-sentry-component="StyledHeader" data-sentry-source-file="StyledHeader.tsx">
      {/* Animated Overlay */}
      <Box sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'linear-gradient(to top right, rgba(255, 255, 255, 0.05), transparent)',
      animation: `${pulseAnimation} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`
    }} data-sentry-element="Box" data-sentry-source-file="StyledHeader.tsx" />

      {/* Main Heading */}
      <Typography variant="h4" sx={{
      color: '#FFFFFF',
      fontSize: {
        xs: '1.5rem',
        md: '1.875rem'
      },
      fontWeight: 'bold',
      marginBottom: '1rem',
      textAlign: 'center',
      position: 'relative',
      zIndex: 1
    }} data-sentry-element="Typography" data-sentry-source-file="StyledHeader.tsx">
        Web3 signals powered by{' '}
        <Box component="span" sx={{
        color: '#A7F3D0'
      }} data-sentry-element="Box" data-sentry-source-file="StyledHeader.tsx">
          200,000
        </Box>{' '}
        web3 pioneers.
      </Typography>

      {/* Subheading */}
      <Typography sx={{
      color: '#E0E7FF',
      fontSize: '1.125rem',
      lineHeight: '1.75',
      marginBottom: '2rem',
      textAlign: 'center',
      position: 'relative',
      zIndex: 1
    }} data-sentry-element="Typography" data-sentry-source-file="StyledHeader.tsx">
        Save hours of screen time: get curated trends & tokens in seconds
      </Typography>

      {/* Login Button */}
      <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      gap: '0.5rem'
    }} data-sentry-element="Box" data-sentry-source-file="StyledHeader.tsx">
        <Button onClick={handleLoginClick} variant="contained" sx={{
        backgroundColor: '#A7F3D0',
        '&:hover': {
          backgroundColor: '#6EE7B7'
        },
        color: '#065F46',
        padding: '0.5rem 1.5rem',
        borderRadius: '0.5rem',
        fontWeight: 500,
        display: 'flex',
        gap: '0.5rem',
        transition: 'all 300ms'
      }} data-sentry-element="Button" data-sentry-source-file="StyledHeader.tsx">
          Log in with Lens →
        </Button>
      </Box>

      {/* Explanation Text */}
      <Typography sx={{
      color: '#C7D2FE',
      fontSize: '0.875rem',
      textAlign: 'center',
      marginTop: '1rem',
      maxWidth: '28rem',
      margin: '1rem auto 0'
    }} data-sentry-element="Typography" data-sentry-source-file="StyledHeader.tsx">
        Connect your Lens profile to unlock personalized curation based on your
        web3 activity
      </Typography>
    </Box>;
};
export default StyledHeader;