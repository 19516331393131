import { Box, Button, Stack, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';
const Chart = dynamic(() => import('react-apexcharts'), {
  ssr: false
});
import DashboardCard from '@/components/shared/DashboardCard';
import { useEffect, useState } from 'react';
import { useBackend } from '@/context/backend';
import { useSession } from '@/context/session';
import { ProtocolEnum } from '@/types/custom';
interface ChartData {
  dates: string[];
  series: Series[];
}
interface Series {
  name: string;
  data: number[];
}
interface TopicData {
  date?: string;
  week_start?: string;
  tag_name: string;
  topic_count: string;
}
interface SeriesWithTotal extends Series {
  total: number;
}
type FrequencyType = 'daily' | 'weekly';
const TopicsChart = () => {
  const {
    fetchTopicsChart
  } = useBackend();
  const {
    isLensUser
  } = useSession();
  const [chartData, setChartData] = useState<ChartData>({
    dates: [],
    series: []
  });
  const [frequency, setFrequency] = useState<FrequencyType>('daily');
  useEffect(() => {
    getTopicsData();
  }, [frequency, isLensUser]);
  const processChartData = (data: TopicData[]) => {
    const dateField = data[0]?.date ? 'date' : 'week_start';
    const uniqueDates = Array.from(new Set(data.map(item => {
      const date = new Date(item[dateField] as string);
      return frequency === 'weekly' ? `Week of ${date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
      })}` : (item[dateField] as string).split('T')[0];
    }))).sort();
    const uniqueTags = Array.from(new Set(data.map(item => item.tag_name)));
    const series = uniqueTags.map(tag => {
      const tagData = uniqueDates.map(displayDate => {
        const matchingItem = data.find(item => {
          const itemDate = new Date(item[dateField] as string);
          const formattedDate = frequency === 'weekly' ? `Week of ${itemDate.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
          })}` : (item[dateField] as string).split('T')[0];
          return formattedDate === displayDate && item.tag_name === tag;
        });
        return matchingItem ? parseInt(matchingItem.topic_count) : 0;
      });
      return {
        name: tag,
        data: tagData
      };
    });
    const topSeries = series.map((s): SeriesWithTotal => ({
      ...s,
      total: s.data.reduce((a, b) => a + b, 0)
    })).sort((a, b) => b.total - a.total).slice(0, 5).map(({
      name,
      data
    }): Series => ({
      name,
      data
    }));
    setChartData({
      dates: uniqueDates,
      series: topSeries
    });
  };
  const getTopicsData = async () => {
    const protocol = ProtocolEnum.LENS;
    const res = await fetchTopicsChart(protocol, frequency);
    processChartData(res);
  };
  const SelectButtons = () => {
    return <Stack direction="row" gap={0.5} data-sentry-element="Stack" data-sentry-component="SelectButtons" data-sentry-source-file="TopicsChart.tsx">
        <Button variant={frequency === 'daily' ? 'contained' : 'outlined'} onClick={() => setFrequency('daily')} data-sentry-element="Button" data-sentry-source-file="TopicsChart.tsx">
          Daily
        </Button>
        <Button variant={frequency === 'weekly' ? 'contained' : 'outlined'} onClick={() => setFrequency('weekly')} data-sentry-element="Button" data-sentry-source-file="TopicsChart.tsx">
          Weekly
        </Button>
      </Stack>;
  };
  const optionslinechart: ApexCharts.ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      foreColor: '#adb0bb',
      zoom: {
        enabled: true,
        type: 'x'
      },
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: chartData.dates,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: '12px'
        }
      }
    },
    grid: {
      show: true,
      borderColor: '#90A4AE',
      strokeDashArray: 0
    },
    colors: ['#1E88E5', '#00ACC1', '#43A047', '#E53935', '#FDD835'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    legend: {
      show: false
    },
    tooltip: {
      theme: 'dark',
      y: {
        formatter: (value: number) => `${value} topics`
      }
    },
    yaxis: {
      title: {
        text: 'Topic Count'
      }
    }
  };
  return <Box data-sentry-element="Box" data-sentry-component="TopicsChart" data-sentry-source-file="TopicsChart.tsx">
      <DashboardCard title="Topics on Web3 Social" subtitle="Tracking popularity trends of Web3 topics over time. Shows which conversations are gaining or losing momentum." action={<SelectButtons />} data-sentry-element="DashboardCard" data-sentry-source-file="TopicsChart.tsx">
        <Chart options={optionslinechart} series={chartData.series} type="line" height={360} width={'90%'} data-sentry-element="Chart" data-sentry-source-file="TopicsChart.tsx" />
      </DashboardCard>
    </Box>;
};
export default TopicsChart;