// ** React & Mui
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Stack, Typography, useTheme } from '@mui/material';

// ** Components
import UniswapCard from '@/components/uniswap/UniswapCard';
import TokensChart from '../desktop/charts/TokensChart';
import TopicsChart from '../desktop/charts/TopicsChart';
import CategoriesChart from '../desktop/charts/CategoriesChart';
import StyledHeader from '../StyledHeader';

// ** Context & Utils
import { useSession } from '@/context/session';
import { useBackend } from '@/context/backend';
import { ProtocolEnum, UniswapTokenStatsData } from '@/types/custom';
import { TopTopicMobileCard } from './TopTopicMobileCard';
import { Topic } from '@/utils/constants/types';
import { MUI_BUTTON_COLORS } from '@/utils/constants/literals';
const OverviewMobile = () => {
  const {
    notLoggedIn,
    isLoadingSignIn
  } = useSession();
  const {
    getTopics,
    fetchUniswapTokensData,
    fetchTopicsTags
  } = useBackend();
  const [isLoading, setIsLoading] = useState(false);
  const [topTopics, setTopTopics] = useState<Topic[]>();
  const [topTokens, setTopTokens] = useState<UniswapTokenStatsData[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchTopTopic();
      await fetchTokensData();
      setIsLoading(false);
    };
    fetchData();
  }, []);
  const fetchTopTopic = async () => {
    try {
      const [tagsData = [], topicsData = []] = await Promise.all([fetchTopicsTags(), getTopics(ProtocolEnum.LENS)]);
      const colorfulExistingTags = tagsData.map((tag, index) => ({
        ...tag,
        color: MUI_BUTTON_COLORS[index % MUI_BUTTON_COLORS.length]
      }));
      const existingTopics = topicsData.map(topic => ({
        ...topic,
        tag_color: colorfulExistingTags.find(tag => tag.id === topic.tag_id)?.color
      }));
      if (!existingTopics) return;
      setTopTopics(existingTopics.slice(0, 3));
    } catch (error) {
      console.log('Error while fetching top topic', error);
    }
  };
  const fetchTokensData = async () => {
    try {
      const tokens = await fetchUniswapTokensData(ProtocolEnum.LENS, undefined, 1, 20);
      if (!tokens) return;
      const filteredTokens = tokens.slice(0, 3);
      setTopTokens(filteredTokens);
    } catch (error) {
      console.log('Error while fetching top topic', error);
    }
  };
  return <Stack direction="column" gap={4} sx={{
    borderRadius: 2
  }} data-sentry-element="Stack" data-sentry-component="OverviewMobile" data-sentry-source-file="index.tsx">
      <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">{notLoggedIn && !isLoadingSignIn ? <StyledHeader /> : null}</Box>
      {/* Sección Topics */}
      <Stack gap={2} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <Typography variant="h5" fontWeight="bold" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            Hot conversations in Web3 social
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            Discover the most engaging conversations happening right now across
            Web3 social platforms.
          </Typography>
        </Box>
        <Grid container direction="row" spacing={2} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {topTopics?.map(topic => <Grid item key={topic.id} xs={12} lg={4}>
              <TopTopicMobileCard topicData={topic as any} isLoading={isLoading} />
            </Grid>)}
        </Grid>
      </Stack>

      <Stack direction="column" mt="1.5em" alignContent="center" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Typography variant="h5" alignContent="center" sx={{
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'transparent'
      }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          Top 3 tokens today
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          Most active tokens by volume, marketplace activity, and price movement
          in the last 24hrs.
        </Typography>
      </Stack>
      <Stack data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Grid container spacing={2} justifyContent="center" sx={{
        overflow: 'hidden',
        mt: '0.5em'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {topTokens?.map(token => <Grid item key={`${token.chain}-${token.symbol}`} sm={6}>
              <UniswapCard token={token} isOverview />
            </Grid>)}
        </Grid>
      </Stack>

      <Stack data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Grid container spacing={2} justifyContent="left" sx={{
        overflow: 'hidden',
        mt: '0.5em'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <TopicsChart data-sentry-element="TopicsChart" data-sentry-source-file="index.tsx" />
          </Grid>
          <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <CategoriesChart data-sentry-element="CategoriesChart" data-sentry-source-file="index.tsx" />
          </Grid>
        </Grid>
      </Stack>
    </Stack>;
};
export default OverviewMobile;