import { Box, Button, Stack, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';
const Chart = dynamic(() => import('react-apexcharts'), {
  ssr: false
});
import DashboardCard from '@/components/shared/DashboardCard';
import { useBackend } from '@/context/backend';
import { useSession } from '@/context/session';
import { useEffect, useState } from 'react';
import { ProtocolEnum } from '@/types/custom';
interface ChartData {
  dates: string[];
  series: Series[];
}
interface Series {
  name: string;
  data: number[];
}
interface CategoryData {
  trade_date: string;
  category: string;
  total_unique_users?: string;
  token_count?: string;
}
type FrequencyType = 'users' | 'tokens';
const CategoriesChart = () => {
  const theme = useTheme();
  const {
    fetchCategoriesChart
  } = useBackend();
  const {
    isLensUser
  } = useSession();
  const [chartData, setChartData] = useState<ChartData>({
    dates: [],
    series: []
  });
  const [frequency, setFrequency] = useState<FrequencyType>('users');
  const processChartData = (data: CategoryData[]) => {
    const uniqueDates = Array.from(new Set(data.map(item => new Date(item.trade_date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    })))).sort();
    const uniqueCategories = Array.from(new Set(data.map(item => item.category)));
    const series = uniqueCategories.map(category => {
      const categoryData = uniqueDates.map(displayDate => {
        const matchingItem = data.find(item => {
          const itemDate = new Date(item.trade_date).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
          });
          return itemDate === displayDate && item.category === category;
        });
        const value = frequency === 'users' ? matchingItem?.total_unique_users : matchingItem?.token_count;
        return value ? parseInt(value) : 0;
      });
      return {
        name: category,
        data: categoryData
      };
    });
    const sortedSeries = series.map(s => ({
      ...s,
      total: s.data.reduce((a, b) => a + b, 0)
    })).sort((a, b) => b.total - a.total).slice(0, 5).map(({
      name,
      data
    }) => ({
      name,
      data
    }));
    setChartData({
      dates: uniqueDates,
      series: sortedSeries
    });
  };
  const getCategoriesData = async () => {
    const protocol = ProtocolEnum.LENS;
    try {
      const res = await fetchCategoriesChart(protocol, frequency);
      processChartData(res);
    } catch (error) {
      console.error('Error fetching categories data:', error);
    }
  };
  useEffect(() => {
    getCategoriesData();
  }, [frequency, isLensUser]);
  const optionslinechart: ApexCharts.ApexOptions = {
    chart: {
      height: 350,
      type: 'line',
      fontFamily: "'Plus Jakarta Sans', sans-serif",
      foreColor: '#adb0bb',
      zoom: {
        enabled: true,
        type: 'x'
      },
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: chartData.dates,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          fontSize: '12px'
        }
      }
    },
    grid: {
      show: true,
      borderColor: '#90A4AE',
      strokeDashArray: 0
    },
    colors: ['#1E88E5', '#00ACC1', '#43A047', '#FDD835', '#FB8C00', '#E53935', '#8E24AA', '#5E35B1', '#3949AB', '#00897B'],
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    legend: {
      show: false
    },
    tooltip: {
      theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
      y: {
        formatter: function (value: number) {
          return value.toLocaleString() + (frequency === 'users' ? ' users' : ' tokens');
        }
      }
    }
  };
  const SelectButtons = () => {
    return <Stack direction="row" gap={0.5} data-sentry-element="Stack" data-sentry-component="SelectButtons" data-sentry-source-file="CategoriesChart.tsx">
        <Button variant={frequency === 'users' ? 'contained' : 'outlined'} onClick={() => setFrequency('users')} data-sentry-element="Button" data-sentry-source-file="CategoriesChart.tsx">
          Users interest
        </Button>
        <Button variant={frequency === 'tokens' ? 'contained' : 'outlined'} onClick={() => setFrequency('tokens')} data-sentry-element="Button" data-sentry-source-file="CategoriesChart.tsx">
          Token diversification
        </Button>
      </Stack>;
  };
  return <Box data-sentry-element="Box" data-sentry-component="CategoriesChart" data-sentry-source-file="CategoriesChart.tsx">
      <DashboardCard title="Category Analysis" subtitle="This graph shows how many tokens are being bought in each category." action={<SelectButtons />} data-sentry-element="DashboardCard" data-sentry-source-file="CategoriesChart.tsx">
        <Chart options={optionslinechart} series={chartData.series} type="line" height={360} width={'90%'} data-sentry-element="Chart" data-sentry-source-file="CategoriesChart.tsx" />
      </DashboardCard>
    </Box>;
};
export default CategoriesChart;