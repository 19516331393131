import { Grid } from '@mui/material';
import PageContainer from '@/components/container/PageContainer';
import OverviewComponent from '@/components/overview/OverviewComponent';
import Breadcrumb from '@/layouts/full/shared/breadcrumb/Breadcrumb';
export default function Overview() {
  return <PageContainer data-sentry-element="PageContainer" data-sentry-component="Overview" data-sentry-source-file="index.tsx">
      <Grid container spacing={3} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
        <Grid item xs={12} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <OverviewComponent data-sentry-element="OverviewComponent" data-sentry-source-file="index.tsx" />
        </Grid>
      </Grid>
    </PageContainer>;
}