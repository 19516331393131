// ** React & Mui
import { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Stack, Typography, useTheme } from '@mui/material';

// ** Components
import { TopTopicDesktopCard } from './TopTopicDesktopCard';
import UniswapCard from '@/components/uniswap/UniswapCard';
import TokensChart from './charts/TokensChart';
import TopicsChart from './charts/TopicsChart';
import CategoriesChart from './charts/CategoriesChart';
import StyledHeader from '../StyledHeader';

// ** Context & Utils
import { useBackend } from '@/context/backend';
import { useSession } from '@/context/session';
import { ProtocolEnum, UniswapTokenStatsData } from '@/types/custom';
import { Topic } from '@/utils/constants/types';
import { MUI_BUTTON_COLORS } from '@/utils/constants/literals';
const OverviewDesktop = () => {
  const {
    notLoggedIn,
    isLoadingSignIn
  } = useSession();
  const {
    getTopics,
    fetchUniswapTokensData,
    fetchTopicsTags
  } = useBackend();
  const [isLoading, setIsLoading] = useState(false);
  const [topTopics, setTopTopics] = useState<Topic[]>();
  const [topTokens, setTopTokens] = useState<UniswapTokenStatsData[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await fetchTopTopic();
      await fetchTokensData();
      setIsLoading(false);
    };
    fetchData();
  }, []);
  const fetchTopTopic = async () => {
    try {
      const [tagsData = [], topicsData = []] = await Promise.all([fetchTopicsTags(), getTopics(ProtocolEnum.LENS)]);
      const colorfulExistingTags = tagsData.map((tag, index) => ({
        ...tag,
        color: MUI_BUTTON_COLORS[index % MUI_BUTTON_COLORS.length]
      }));
      const existingTopics = topicsData.map(topic => ({
        ...topic,
        tag_color: colorfulExistingTags.find(tag => tag.id === topic.tag_id)?.color
      }));
      if (!existingTopics) return;
      setTopTopics(existingTopics.slice(0, 3));
    } catch (error) {
      console.log('Error while fetching top topic', error);
    }
  };
  const fetchTokensData = async () => {
    try {
      const tokens = await fetchUniswapTokensData(ProtocolEnum.LENS, undefined, 1, 20);
      if (!tokens) return;
      const filteredTokens = tokens.slice(0, 3);
      setTopTokens(filteredTokens);
    } catch (error) {
      console.log('Error while fetching top topic', error);
    }
  };
  return <Box data-sentry-element="Box" data-sentry-component="OverviewDesktop" data-sentry-source-file="index.tsx">
      <Stack direction="column" gap={4} sx={{
      borderRadius: 2
    }} data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">{notLoggedIn && !isLoadingSignIn ? <StyledHeader /> : null}</Box>
        {/* Sección Topics */}
        <Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
          <Typography variant="h5" fontWeight="bold" px={2} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            Hot conversations in Web3 social
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" px={2} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
            Discover the most engaging conversations happening right now across
            Web3 social platforms.
          </Typography>
          <Grid container direction="row" spacing={{
          xs: 1,
          md: 0.02
        }} sx={{
          py: 0.2,
          px: 1
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            {topTopics?.map(topic => <Grid item key={topic.id} xs={12} lg={4} sx={{
            display: 'flex',
            '& > *': {
              width: '100%'
            }
          }}>
                <TopTopicDesktopCard isLoading={isLoading} topicData={topic as any} />
              </Grid>)}
          </Grid>
        </Box>
      </Stack>
      <Stack direction="column" mt="1em" alignContent="center" data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Typography variant="h5" alignContent="center" px={2} sx={{
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'transparent'
      }} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          Top 3 tokens today
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" px={2} data-sentry-element="Typography" data-sentry-source-file="index.tsx">
          Most active tokens by volume, marketplace activity, and price movement
          in the last 24hrs.
        </Typography>
      </Stack>
      <Stack data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Grid container spacing={2} justifyContent="center" sx={{
        overflow: 'hidden',
        mt: '0.5em',
        p: '0.8em'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          {topTokens?.map(token => <Grid item key={`${token.chain}-${token.symbol}`} sm={4}>
              <Box sx={{
            height: '100%',
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              transform: 'translateY(-4px)'
            }
          }}>
                <UniswapCard token={token} />
              </Box>
            </Grid>)}
        </Grid>
      </Stack>
      <Stack data-sentry-element="Stack" data-sentry-source-file="index.tsx">
        <Grid container spacing={2} justifyContent="left" sx={{
        overflow: 'hidden',
        mt: '0.5em',
        p: '0.8em'
      }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
          <Grid item xs={6} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <TopicsChart data-sentry-element="TopicsChart" data-sentry-source-file="index.tsx" />
          </Grid>
          <Grid item xs={6} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
            <CategoriesChart data-sentry-element="CategoriesChart" data-sentry-source-file="index.tsx" />
          </Grid>
        </Grid>
      </Stack>
    </Box>;
};
export default OverviewDesktop;